import { Grid, Typography } from "@mui/material";
import CardImageTitle from "@/components/cards/CardImageTitle/CardImageTitle";
import { MenuType } from "@/store/types/coreTypes";
import { AppState, useSelector } from "@/store/store/Store";

const MainCategory = () => {
  const menu: MenuType[] = useSelector(
    (state: AppState) => state.coreReducer.menu
  );

  return (
    <>
      <Typography
        variant="body1"
        fontSize={"22px"}
        textAlign={"center"}
        fontWeight={700}
      >
        CATEGORIAS
      </Typography>
      <Grid container spacing={2} justifyContent={"space-evenly"}>
        {menu &&
          menu.map((subitem: any, index: any) => {
            if (subitem.thumb == null) {
              return;
            }

            return (
              <Grid item xxs={12} xs={6} sm={4} md={3} lg={3} key={index}>
                <CardImageTitle
                  name={subitem.name ?? ""}
                  href={subitem.href ?? ""}
                  thumb={subitem.thumb ?? ""}
                  altText={subitem.altText ?? ""}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default MainCategory;
