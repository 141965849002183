import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import Link from "next/link";
import Image from "next/image";

interface CardImageTitleProps {
  name: string;
  href: string;
  thumb: string;
  altText: string;
  priority?: boolean;
}

const CardImageTitle = ({
  name,
  href,
  thumb,
  altText,
  priority,
}: CardImageTitleProps) => {
  const theme = useTheme();

  return (
    <>
      {(!name || !thumb || !href) && (
        <Skeleton
          variant="rounded"
          style={{
            backgroundImage: `linear-gradient(to bottom left, ${theme.palette.secondary.light}, ${theme.palette.secondary.extraLight}, ${theme.palette.primary.extraLight},  ${theme.palette.primary.light})`,
            marginTop: "20px",
            borderRadius: "15px",
            width: "100%",
            height: "auto",
            aspectRatio: "640 / 832",
          }}
        ></Skeleton>
      )}
      {name && thumb && href && (
        <Card
          style={{
            borderRadius: "15px",
            position: "relative",
            // aspectRatio: 3 / 4,
            marginTop: "20px",
          }}
        >
          <CardHeader
            style={{
              position: "absolute",
              top: "0",
              width: "100%",
            }}
          />
          <Link href={href}>
            <div
              style={{
                // backgroundImage: `linear-gradient(to bottom left, ${theme.palette.secondary.light}, ${theme.palette.secondary.extraLight}, ${theme.palette.primary.extraLight},  ${theme.palette.primary.light})`,
                // borderRadius: "20px",
                aspectRatio: "640 / 832",
                position: "relative",
              }}
            >
              <Image
                src={thumb}
                alt={altText}
                fill
                priority={priority}
                loading={priority ? undefined : "lazy"}
                placeholder="blur"
                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAAVklEQVRIDfXBAQEAAAABIP3DHNmAAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEGZiIBAAAAAABhBmYiAQAAAAAAYQZmIgEAAAAAAGEhQGvzULNwAAAABJRU5ErkJggg=="
                sizes="(max-width: 350px) 100vw, (max-width: 600px) 50vw, (max-width: 900px) 33vw, (max-width: 1200px) 25vw, 300px"
                quality={100}
              />
            </div>
          </Link>
          <CardContent
            style={{
              // borderTop: `1px solid #000`,
              position: "absolute",
              bottom: "0",
              backgroundColor: theme.palette.primary.light,
              opacity: 0.9,
              width: "100%",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Link href={href}>
              <Typography
                variant="body1"
                fontSize={"1rem"}
                color="#000"
                textAlign={"center"}
                fontWeight={700}
              >
                {name}
              </Typography>
            </Link>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default CardImageTitle;
