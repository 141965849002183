import { Box, Button, Grid, Typography } from "@mui/material";
import CardImageTitle from "@/components/cards/CardImageTitle/CardImageTitle";
import CardVerToHorAction from "@/components/cards/CardVerToHorAction/CardVerToHorAction";
import Link from "next/link";

interface RecentPostsProps {
  posts: Item[];
  limit?: number;
}

type Item = {
  name: string;
  date: string;
  thumb: string;
  altText: string;
  url?: string;
  slug?: string;
  sinopse: string;
};

const RecentPosts = ({ posts, limit = 12 }: RecentPostsProps) => {
  return (
    <>
      <Typography
        variant="body1"
        fontSize={"22px"}
        textAlign={"center"}
        fontWeight={700}
      >
        ARTIGOS RECENTES
      </Typography>
      <Grid container spacing={3}>
        {posts &&
          posts.length > 0 &&
          posts.slice(0, limit).map((item: Item, index: number) => (
            <Grid item xxs={12} sm={6} md={4} lg={4} key={index}>
              <CardVerToHorAction
                title={item.name}
                subtitle={new Date(item.date).toLocaleDateString()}
                sinopse={item.sinopse}
                thumb={item.thumb}
                altText={item.altText}
                url={`/blog/post/${item.slug}`}
              />
            </Grid>
          ))}
      </Grid>
      <Box
        pt={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link href="/blog/posts/">
          <Button variant="contained" color="secondary">
            Ver todos os artigos...
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default RecentPosts;
