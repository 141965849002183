import React from "react";
import { Hidden } from "@mui/material";
import { Grid } from "@mui/material";
import { PostType } from "@/store/types/blogTypes";
import CardHeroGrid from "@/components/cards/CardHeroGrid";

interface HighlightPostsProps {
  posts: PostType[];
}

const HighlightPosts: React.FC<HighlightPostsProps> = ({ posts }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xxs={12} sm={6} md={6}>
          <CardHeroGrid
            color={"primary"}
            title={posts[0].name}
            image={posts[0].thumb}
            altText={posts[0].altText}
            url={`/blog/post/${posts[0].slug}`}
            categories={posts[0].categories}
          />
        </Grid>
        <Grid item container xxs={12} sm={6} md={6} spacing={2}>
          <Grid item xxs={12} sm={12} md={6}>
            <CardHeroGrid
              color={"secondary"}
              title={posts[1].name}
              image={posts[1].thumb}
              altText={posts[1].altText}
              url={`/blog/post/${posts[1].slug}`}
              categories={posts[1].categories}
            />
          </Grid>
          <Hidden mdDown>
            <Grid item md={6}>
              <CardHeroGrid
                color={"terciary"}
                title={posts[2].name}
                image={posts[2].thumb}
                altText={posts[2].altText}
                url={`/blog/post/${posts[2].slug}`}
                categories={posts[2].categories}
              />
            </Grid>
            <Grid item md={6}>
              <CardHeroGrid
                color={"neutral"}
                title={posts[3].name}
                image={posts[3].thumb}
                altText={posts[3].altText}
                url={`/blog/post/${posts[3].slug}`}
                categories={posts[3].categories}
              />
            </Grid>

            <Grid item md={6}>
              <CardHeroGrid
                color={"neutral"}
                title={posts[4].name}
                image={posts[4].thumb}
                altText={posts[4].altText}
                url={`/blog/post/${posts[4].slug}`}
                categories={posts[4].categories}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Grid item xxs={12} sm={6}>
            <CardHeroGrid
              color={"terciary"}
              title={posts[2].name}
              image={posts[2].thumb}
              altText={posts[2].altText}
              url={`/blog/post/${posts[2].slug}`}
              categories={posts[2].categories}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <CardHeroGrid
              color={"neutral"}
              title={posts[3].name}
              image={posts[3].thumb}
              altText={posts[3].altText}
              url={`/blog/post/${posts[3].slug}`}
              categories={posts[3].categories}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default HighlightPosts;
