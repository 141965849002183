import { Box, Container, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface SectionProps extends React.ComponentProps<typeof Box> {
  maxWidth?: "lg" | "sm" | "xs";
  children: ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "terciary"
    | "neutral"
    | "white"
    | "special";
  dark?: boolean;
  //   extraDark?: boolean;
  light?: boolean;
  extraLight?: boolean;
}

const Section = ({
  maxWidth,
  children,
  mt,
  mb,
  my,
  m,
  pt,
  pb,
  py,
  p,
  color,
  dark,
  //   extraDark,
  light,
  extraLight,
}: SectionProps) => {
  const theme = useTheme();

  const getColor = () => {
    if (color) {
      if (color == "white") return theme.palette.white.main;
      let selectedColor;

      switch (color) {
        case "primary":
          selectedColor = theme.palette.primary;
          break;
        case "secondary":
          selectedColor = theme.palette.secondary;
          break;
        case "terciary":
          selectedColor = theme.palette.terciary; // Substitua por sua cor terciária real
          break;
        case "neutral":
          selectedColor = theme.palette.neutral; // Substitua por sua cor neutra real
          break;
        default:
          selectedColor = theme.palette.primary; // Cor padrão
          break;
      }

      if (dark) {
        return selectedColor.dark;
      } else if (light) {
        return selectedColor.light;
      } else if (extraLight) {
        return selectedColor.extraLight;
      } else {
        return selectedColor.main;
      }
    } else {
      return "transparent";
    }
  };
  return (
    <Box
      mt={mt}
      mb={mb}
      m={m}
      my={my}
      pt={pt}
      pb={pb}
      p={p}
      py={py}
      style={{
        backgroundColor: color != "special" ? getColor() : "unset",
        backgroundImage:
          color == "special"
            ? `linear-gradient(to bottom right, ${theme.palette.secondary.extraLight}, ${theme.palette.primary.light},${theme.palette.secondary.light}, ${theme.palette.primary.extraLight} )`
            : "unset",
      }}
    >
      {maxWidth ? (
        <Container maxWidth={maxWidth}>{children}</Container>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};

export default Section;
