import React from "react";
import {
  Typography,
  Button,
  Skeleton,
  CardMedia,
  CardContent,
  useTheme,
  styled,
  Card,
} from "@mui/material";

import { useRouter } from "next/router";
import Link from "next/link";

const CardStyled = styled(Card)`
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  margin-top: 15px;
`;

interface CardVerToHorActionProps {
  title: string;
  subtitle: string;
  sinopse: string;
  thumb: string;
  altText: string;
  url: string;
}
const CardVerToHorAction = ({
  title,
  subtitle,
  sinopse,
  thumb,
  altText,
  url,
}: CardVerToHorActionProps) => {
  const theme = useTheme();
  const router = useRouter();

  return (
    <>
      {(!title || !thumb) && (
        <CardStyled>
          <Skeleton
            variant="rounded"
            sx={{
              height: "450px",
            }}
          ></Skeleton>
        </CardStyled>
      )}
      {thumb && (
        <CardStyled>
          <Link href={url} title={altText}>
            <CardMedia
              component="img"
              sx={{
                height: "250px",
                cursor: "pointer",
                width: "100%",
                backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.extraLight}, ${theme.palette.secondary.light}, ${theme.palette.primary.light}, ${theme.palette.secondary.extraLight})`,
              }}
              image={thumb}
              alt={altText}
              // onClick={() => router.push(url)}
            />
          </Link>
          <CardContent sx={{ flex: "1", padding: "20px" }}>
            <Link href={url} title={altText}>
              <Typography
                variant="h2"
                fontWeight={700}
                style={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
                onClick={() => router.push(url)}
              >
                {title}
              </Typography>{" "}
            </Link>
            <Typography variant="body2" color={"neutral.dark"} mb={2}>
              {subtitle}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
              }}
            >
              {sinopse}
            </Typography>
            <Button
              size="small"
              color="secondary"
              variant="text"
              // style={{ color: "#000" }}
              onClick={() => router.push(url)}
            >
              Continue lendo...
            </Button>
          </CardContent>
        </CardStyled>
      )}
    </>
  );
};

export default CardVerToHorAction;
