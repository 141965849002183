import React, { useState } from "react";
import Head from "next/head";
import { Grid, Button, Typography, Alert } from "@mui/material";
import { handleSubmit } from "@/utils/handleSubmit/handleSubmit";
import { initialState, schema } from "./FormRecoverPassSchema";
import InputIcon from "@/components/Form/InputIcon";
import { putPasswordKey } from "@/store/reducers/AuthSlice";
import { dispatch } from "@/store/store/Store";
import { alertOpen, loadingOff, loadingOn } from "@/store/reducers/CoreSlice";
import { useRouter } from "next/router";

interface FormRecoverProps {
  queryKey: string | string[];
}

const FormRecover: React.FC<FormRecoverProps> = ({ queryKey }) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [openModalSign, setOpenModalSign] = useState(false);
  const router = useRouter();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmitSuccess = () => {
    setErrors(initialState);
    onSubmitForm(formData);
  };

  const onSubmitFail = (errors: any) => {
    setErrors(errors);
  };

  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    handleSubmit({ schema, formData, onSubmitSuccess, onSubmitFail });
  };

  const handleRemoveKey = () => {
    const currentQuery = { ...router.query };

    delete currentQuery.key;

    router.replace({
      pathname: router.pathname,
      query: currentQuery,
    });
  };

  const onSubmitForm = async (formData: any) => {
    setError("");
    setSuccess("");
    dispatch(loadingOn());
    await putPasswordKey(
      queryKey,
      formData,
      (res: any) => {
        handleRemoveKey();
        dispatch(
          alertOpen({
            type: "SUCCESS",
            local: "",
            message: res.data.message,
            button: false,
            ssr: false,
          })
        );
      },
      (err: any) => {
        setError(err.message);
      }
    );
    dispatch(loadingOff());
  };

  return (
    <>
      <Head>
        <title>Hora da Atividade</title>
        <meta name="description" content="Hora da Atividade" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      {error && <Alert severity="error">{error}</Alert>}

      <form onSubmit={handleSubmitForm}>
        <Grid container direction="column" spacing={2} mt={1} mb={2}>
          <Grid item>
            <Typography variant="body1">Digite sua nova senha!</Typography>
          </Grid>
          <Grid item>
            <InputIcon
              placeholder="Digite sua nova senha"
              type="password"
              onChange={handleChange}
              value={formData.password}
              name="password"
            />
            {errors.password && (
              <Typography ml={1} variant={"caption"} color={"error"}>
                * {errors.password}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <InputIcon
              placeholder="Digite novamente sua nova senha"
              type="password"
              onChange={handleChange}
              value={formData.confirmation}
              name="confirmation"
            />
            {errors.confirmation && (
              <Typography ml={1} variant={"caption"} color={"error"}>
                * {errors.confirmation}
              </Typography>
            )}
          </Grid>

          <Grid item mt={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              type="submit"
            >
              {success ? "Enviar novamente" : "Salvar"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormRecover;
