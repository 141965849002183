import React, { useEffect } from "react";
import Head from "next/head";
import {
  fetchCategoryListPageStatic,
  fetchHighlightsStatic,
  fetchLastPostsStatic,
} from "@/store/reducers/BlogSlice";
import { dispatch } from "@/store/store/Store";
import { alertOpsSSR } from "@/store/reducers/CoreSlice";
import Section from "@/components/section/Section";
import HighlightPosts from "@/sections/Blog/HighlightPosts/HighlightPosts";
import { useRouter } from "next/router";
import FormRecoverPass from "@/sections/_Layout/Auth/RecoverPass/FormRecoverPass";
import MainCategory from "@/sections/Blog/MainCategory/MainCategory";
import RecentPosts from "@/sections/Blog/RecentPosts/RecentPosts";
import dynamic from "next/dynamic";
import { Box } from "@mui/material";

const InArticle = dynamic(
  () => import("../components/adsense/InArticle/InArticle"),
  {
    ssr: false,
  }
);

export default function Home({ posts, categories, error, highlights }: any) {
  const router = useRouter();
  const queryKey = router.query.key;

  useEffect(() => {
    if (error) {
      dispatch(alertOpsSSR());
    }
  }, [error]);

  if (queryKey)
    return (
      <Section py={4} maxWidth="xs">
        <FormRecoverPass queryKey={queryKey} />
      </Section>
    );

  return (
    <>
      <Head>
        <title>Hora da Atividade | Diversão e Aprendizado para Crianças</title>
        <meta
          name="description"
          content="Explore um mundo de diversão e aprendizado para crianças no Hora da Atividade. Encontre atividades criativas, passeios e desenhos para colorir."
          key="description"
        />
        <meta
          name="keywords"
          content="Atividades para Crianças, Diversão em Família, Passeios Infantis, Desenhos para Colorir, Aprendizado Divertido, Recursos Educativos, Entretenimento Infantil, Jogos Criativos, Brincadeiras em Família, Desenvolvimento Infantil"
        />
        <meta name="author" content={"Vanessa Gonçalez"} />
        <link rel="canonical" href={`https://horadaatividade.com.br/`}></link>
        <meta name="robots" content="index, follow" />
      </Head>

      <Section pt={4} pb={6} maxWidth="lg">
        <HighlightPosts posts={highlights} />
        <Box mt={4}>
          <InArticle
            data-ad-slot="4253745556"
            data-full-width-responsive="true"
            data-ad-format="auto"
          />
        </Box>
      </Section>

      <Section py={8} maxWidth="lg" color="special" extraLight>
        <MainCategory />
      </Section>

      <Section py={6} maxWidth="lg">
        <Box mb={4}>
          <InArticle
            data-ad-slot="4253745556"
            data-full-width-responsive="true"
            data-ad-format="auto"
          />
        </Box>
        <RecentPosts posts={posts} />
        <Box mt={4}>
          <InArticle
            data-ad-slot="4253745556"
            data-full-width-responsive="true"
            data-ad-format="auto"
          />
        </Box>
      </Section>
    </>
  );
}

export async function getServerSideProps() {
  try {
    const posts = await fetchLastPostsStatic();
    const categories = await fetchCategoryListPageStatic();
    const highlights = await fetchHighlightsStatic();

    return {
      props: {
        posts,
        categories: categories.categories,
        highlights,
        error: false,
      },
    };
  } catch (error) {
    return {
      props: {
        posts: [],
        categories: [],
        highlights: [],
        error: true,
      },
    };
  }
}
