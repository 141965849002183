import React from "react";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import Link from "next/link";
import Image from "next/image";

type SpecialCardProps = {
  color: "primary" | "secondary" | "terciary" | "neutral";
  title: string;
  image: string;
  altText: string;
  categories: any;
  url: string;
};

const CardHeroGrid: React.FC<SpecialCardProps> = ({
  color,
  title,
  image,
  url,
  altText,
}) => {
  const theme = useTheme();

  // Lista de cores correspondentes às opções de color
  const colorMap = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    terciary: theme.palette.terciary.main,
    neutral: theme.palette.neutral.main,
    // Adicione mais cores ou personalizações conforme necessário
  };

  const colorMapLight = {
    primary: theme.palette.primary.extraLight,
    secondary: theme.palette.secondary.extraLight,
    terciary: theme.palette.terciary.extraLight,
    neutral: theme.palette.neutral.extraLight,
    // Adicione mais cores ou personalizações conforme necessário
  };

  // Obtém a cor correspondente com base na propriedade color
  const backgroundColor = colorMap[color] || theme.palette.primary.main; // Use a cor padrão se não houver correspondência
  const backgroundColorLight =
    colorMapLight[color] || theme.palette.primary.extraLight;

  return (
    <Box>
      <Card
        style={{
          borderRadius: "20px",
          position: "relative",
          // boxShadow: "none !important",
        }}
        elevation={0}
      >
        <Link href={url}>
          <div
            style={{
              backgroundColor: `${backgroundColorLight}`,
              aspectRatio: "1 / 1",
              position: "relative",
            }}
          >
            {image && (
              <Image
                src={image}
                alt={altText}
                fill
                priority
                sizes="(max-width: 650px) 100vw, 50vw"
              />
            )}
          </div>
        </Link>
        <Link href={url}>
          <CardContent
            style={{
              borderTop: `1px solid ${backgroundColor}`,
              position: "absolute",
              bottom: "0",
              backgroundColor: `${backgroundColor}D6`,
              width: "100%",

              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Typography
              variant="body1"
              color="#000"
              fontWeight={700}
              fontSize={"1rem"}
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {title}
            </Typography>
          </CardContent>
        </Link>
      </Card>
    </Box>
  );
};

export default CardHeroGrid;
