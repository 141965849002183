import yup from "@/utils/yupConfig";

export const initialState = {
  password: "",
  confirmation: "",
};

export const schema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .required(
      "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula e uma letra minúscula"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula e uma letra minúscula"
    ),
  confirmation: yup
    .string()
    .required()
    .test("passwords-match", "As senhas precisam ser iguais", function (value) {
      return this.parent.password === value;
    }),
});
